.logo-end {
  max-width: 226px;
  max-height: 118px;
  margin: 0 auto;
  padding-top: 60px;
  margin-bottom: 167px;

  img {
    max-width: 100%;
  }
}

.message {
  margin: 0 auto;
  max-width: 300px;
  max-height: auto;

  @media screen and (min-width: 990px) {
    max-width: 753px;
    max-height: 234px;
  }

  h1 {
    font-family: 'oswald';
    font-weight: bold;
    font-size: 48px;
    color: #fff;
    line-height: 55px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
  }

  h2 {
    font-family: 'proxima-nova';
    font-size: 24px;
    font-weight: 400;
    line-height: 26px;
    color: #fff;
    margin-top: 0;
    margin-bottom: 40px;

    @media screen and (min-width: 990px) {
      text-align: center;
    }
  }

  .strong {
    font-weight: 900;
  }
}

.btn-end-container {
  height: 48px;
  width: 192px;
  margin: 0 auto;
  margin-bottom: 100px;

  .btn-end {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    font-weight:700;
    background-color: #f15d2e;
    border: solid 1px #f15d2e;
    color: #ffffff;
    outline: none;
    text-decoration: none;
    transition: all 200ms;
  }
}

.legal {
  text-align: center;

  a {
    color: #a3a5a6;
    text-decoration: underline;
  }

  a:visited {
    color: #a3a5a6;
    text-decoration: underline;
  }
}