.footer {
  background-color: $primaryColor;
  height: auto;
  margin: 0 auto;
  margin-bottom: -50px;

  @include display(d){
    height: fit-content;
  }

  & .footer-links{
    padding: 16px 0 12px 12px;
    margin-top: 19px;

    @include display(d){
      max-width: 1400px;
      width: 90%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0 auto;

      li:nth-child(n+2):before {
        content: "\2022";
        color: #fff;
        padding: 0 10px;
      }
    }

    & li {
      color: $primaryLightColor;
      font-size: 14px;
      line-height: 35px;
      font-family: $primaryFont;

      @include display(d){
        font-size: 12px;
        line-height: 40px;
      }
    }
  }
}
