.video-modal-container{
    grid-column: 1/-1;
    grid-row: 5;
    width: 90%;
    height: fit-content;
    margin: 30px auto;

    & img {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
    }

    @include display(lg-m){
        grid-row: 4;
    }

    @include display(t){
        max-width: 600px;
        & img {
            margin: 0 auto;
        }
    }

    @include display(d){
        max-width: 740px;
        width: 100%;
        height: auto;
        grid-column: 1/8;
        grid-row: 4;
        margin: 0 auto;
    }
}