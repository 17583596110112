.customModal {
    width: 100%;
    padding: 0!important;
}

.logoModal {
    width: 100%;
    padding: 0!important;
    max-width: none!important;
}

.react-responsive-modal-overlay {
    overflow-y: hidden!important;
    padding: 0!important;
}
