.homepageContainer{
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    max-height: 1000px;
    margin: 0 auto;

    @include display(d){
        display: grid;
        grid-template-columns: repeat(12,1fr);
        grid-template-rows: repeat(11,1fr);
        grid-gap: 0 20px;
    }

    &__logo {
        grid-column: 1/-1;
        grid-row: 1;
        margin: 0 auto;
        margin-top: 30px;

        @include display (d){
            grid-column: 1/8;
        }
    }

    & h1 {
        grid-row: 2;
        grid-column: 1/-1;
        font-family: $headingFont;
        color: $primaryLightColor;
        font-size: 38px;
        line-height: 44px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        width: 90%;
        margin: 0 auto;
        margin-top: 40px;

        @include display(t) {
            width: 80%
        }

        @include display (d){
            grid-column: 1/8;
            width: 100%;
            margin-top: 40px;
            font-size: 38px;
            line-height: 45px;
        }

        @include display (lg-d){
            font-size: 48px;
            line-height: 55px;
        }

        
    }

    & .ctaContainer{
        grid-row: 8;
        grid-column: 1/-1;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 40px;

        @include display(lg-m){
            grid-row: 7;
        }

        @include display(t){
            grid-row: 9;
            margin-top: 0;
        }

        @include display (d){
            grid-column: 1/8;
            width: 100%;
            grid-row: 9;
            margin: auto;
            margin-bottom: 0;
        }

        &__heading{
            font-family: $headingFont;
            font-size: 38px;
            color: $primaryLightColor;
            line-height: 44px;
            font-weight: 700;
            text-transform: uppercase;
            width: 90%;
            margin: 0 auto;

            @include display (d){
                grid-column: 1;
            }
        }

        &__subText{
            font-family: $primaryFont;
            font-size: 18px;
            color: $primaryLightColor;
            line-height: 22px;
            font-weight: 700;

            @include display (d){
                grid-column: 1;
            }
        }
    }
}

.legalContainer{
    color: #fff;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 30px;
    font-size: 12px;
    line-height: 18px;
    text-shadow: 2px 2px 2px rgba(0,0,0,.3);
}