.sliderContainer {
  max-width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 990px) {
    max-width: 1920px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 100%;
  }

  .splide__slide {
    border: none !important;
    border-radius: 16px;
  }
  
  .splide__arrow {
    background: transparent;
    height: 3em;
    width: 3em;
  }
  
  .splide__arrow svg {
    fill: white;
    height: 3em;
    width: 3em;
  }
}

.categorySlider {
  .splide__track {
    height: 240px;

    @media screen and (min-width: 767px) {
      height: 320px;
    }

    ul {
      position: relative;
      top: 40px;
      right: 7px;

      li {
        position: relative;
        transition: transform 0.1s ease-in-out;
        margin-left: 10px;
        &:hover {
         
        }
      }
    }
  }

  .splide__arrow {
    background: transparent;
    height: 3em;
    width: 3em;
  }
  
  .splide__arrow svg {
    fill: white;
    height: 3em;
    width: 3em;
  }

  .splide__arrow--prev {
    display: none;
  }
  
  .splide_list {
    left: 5px;
  }
  
  .splide__slide {
    border: none !important;
    border-radius: 16px;
  }
}

.sliderContainer {
  .splide__track {

    @media screen and (min-width: 990px) {
      height: 390px;
    }

    @media screen and (min-width: 1601px) {
      height: auto;
      min-height: 470px;
    }

    @media screen and (min-width: 1921px) {
      min-height: 640px;
    }
    
    ul {
      position: relative;
      top: 40px;

      li {
        position: relative;
        transition: transform 0.1s ease-in-out;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .splide_list {
    left: 5px;
  }
  
  .splide__slide {
    border: none !important;
    border-radius: 16px;
  }
}

.hero-click {
  background: transparent;
  border: none;
  padding: 0;
  position: relative;

  &:focus {
    outline: none !important;
  }

  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
