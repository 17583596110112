// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
 html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
  min-height: 0;
  min-width: 0;
}
html {
  background-color:  #3B5742;
}
body {
  position: relative;
  .App{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom 100px left -10px;
    background-image: url("../images/Ryan-looking-at-phone.jpg");

    @include display(d){
      background-position: bottom 40px center;
    }
}
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.video-js.nonintro {
  @media screen and (min-width: 990px) {
    min-height: 400px;
  }
}

.video-js {
  width: 100%;

  &.video-bg {
    @media screen and (min-width: 990px) {
      background-color: transparent !important;
    }
  }

  *:focus {
    outline: none;
  }
}

.video-logo {
  width: 0px;
  &.video-bg {
    width: 100%;
  }
}

.video-js .vjs-play-control:before {
  display: none;
}
.video-bg {
  background: radial-gradient(circle 600px at bottom, #458f65, #0d3a11);

  @media screen and (min-width: 990px) {
    background: transparent;
  }

  video.vjs-tech {
    height: 100%;

    @media screen and (min-width: 990px) {
      top: 50%;
    }
  } 
}

.video-js .vjs-big-play-button {
  left: 40% !important;
  top: 65% !important;
  width: 20%;
  height: 20%;
  border: none !important;

  @media screen and (min-width:990px) {
    left: 45% !important;
    top: 70% !important;
  }
}

.video-js .vjs-play-control:before {
  top:20% !important;
  content: '\f101';
  font-size: 48px;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  top: -3px;
}

.logo-static {
  text-align: center;
  position: absolute;
  margin: 0px auto;
  left: 0px;
  width: 100%;
  top: 35%;

  img {
    max-width: 400px;
    height: auto;
  }
}

li {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}