.movieCategory {
  &:last-of-type {
    margin-bottom: 0px;
  }
  .catHeader {
    color: white;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .categorySlider {
    display: flex;
    li {
      .number {
        font-size: 80px;
        font-family: Arial;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.45);
        z-index: 2;
        top: -2px;
        left: 10px;
        position: absolute;
        -webkit-text-stroke: 2px white;

        @media screen and (min-width: 767px) {
          font-size: 80px;
        }
      }
      button {
        background: transparent;
        border: none;
        padding: 0;

        &:focus {
          outline: none!important;
        }
  
        .image-box {
          position: relative;
          margin: auto;
          overflow: hidden;
          width:150px;
          
          @media screen and (min-width: 767px) {
            width:415px;
          }
        }
        * {
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
      }
        .image-box:hover img {
          width: 150px;
          @media screen and (min-width: 767px) {
            width:415px;
          }
        }
        
        .image-box img {
          cursor: pointer;
          transition: all 0.3s;
          display: block;
          width:132px;

          @media screen and (min-width: 767px) {
            width:400px;
          }
        }
      }
    }
  }
}

.hideMobile {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}
