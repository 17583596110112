// Mixin for media query breakpoints - Mobile First approach
@mixin display($size) {
    //When hitting 375 pixels size, pass md-m for argument
    @if $size == md-m {
      @media (min-width: 375px) { @content; }
    } 
  
    //When hitting 425 pixels size, pass lg-m for argument
    @if $size == lg-m {
      @media (min-width: 425px) { @content; }
    }
  
    //When hitting 576 pixels size, pass ls-m for argument
    @if $size == ls-m {
      @media (min-width: 576px) { @content; }
    }
  
    //When hitting 768 pixels size, pass t for argument
    @if $size == t {
      @media (min-width: 768px) { @content; }
    } 
  
    //When hitting 1040 pixels size, pass d for argument
    @else if $size == d {
      @media (min-width: 1040px) { @content; }
    }
  
    //When hitting 1440 pixels size, pass lg-d for argument
    @else if $size == lg-d {
      @media (min-width: 1440px) { @content; }
    }
  
    //When hitting 1600 pixels size, pass xl-d for argument
    @else if $size == xl-d {
      @media (min-width: 1600px) { @content; }
    }
  }