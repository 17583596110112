.banner{
    height: 45px;
    background-color: $primaryColor;

    &-container{
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis; 
        overflow: hidden; 
        white-space: nowrap;
        padding-left: 140px;

    }
    
    &-message{
        display: block;
        color: $primaryLightColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-weight: 700;
        margin-top: 12px;
    }
}