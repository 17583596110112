.overlay {
  opacity: 1;
  // margin-top: -60px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position:fixed;
  z-index:200;
  background: #0d3a11;
  transition: opacity 0.4s ease-in-out;

  @media screen and (min-width:990px) {
    height: 100%;
    // display: flex;
    // position: relative;
    // z-index:199;
    background: radial-gradient(circle 900px at bottom, #458f65, #0d3a11);

  }

  .vjs_video_3-dimensions {
    height: 100vh;

    @media screen and (min-width:990px) {
      height: 500px;
    }
  }

  video {
    @media screen and (min-width:990px) {
      height: 100vh;
    }
  }
}