.a-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 50px;
  font-size: 18px;
  font-family: $primaryFont;
  color: $primaryLightColor;
  font-weight: 700;
  border-radius: 12px;
  background-color: $secondaryColor;
  cursor: pointer;
  margin: 0 auto;
  transition: all 200ms;
  
  &:focus {
    outline: none;
  }
}

.a-btn:hover {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.25);
  transform: translateY(-1px);
  cursor: pointer;
}

// Can we do an inverted play button (white border, white letters, slight drop shadow, hover fills white with styling like this)?  that way it's more see through
.playButton {
  position: absolute;
  left: 50px;
  bottom: 25px;
  height: 18px;
  width: 58px;
  border-radius: 3px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s background-color ease-in-out;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  @media (min-width: 365px){
    left: 20px;
    bottom: 20px;
  }
  @media (min-width: 768px){
    left: 18%;
    bottom:  10%;

  }
  @media (min-width: 992px) {
    right: 0%;
    height: 51px;
    width: 136px;
    bottom: 16%;
    left: 20%;
  }
  @media (min-width: 1600px){
    display: none;
  }
  .triangle {
    width: 0;
    height: 0;

    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 9px solid white;
    transition: 0.5s border-left ease-in-out;
    @media (min-width: 992px) {
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 22px solid white;
    }
  }
  p {
    font-size: 10px;
    line-height: 24px;
    color: white;
    font-weight: bold;
    font-family: proxima-nova;
    transition: 0.5s color ease-in-out;
    margin-left: 5px;
    @media (min-width: 992px) {
      margin-left: 10px;
      font-size: 20px;
    }
  }
  &:hover {
    background-color: white;
    .triangle {
      border-left: 13px solid black;
      @media (min-width: 992px) {
        border-left: 22px solid black;
      }
    }
    p {
      color: initial;
    }
  }
}
