.blackNav {
  padding-top: 30px;
  justify-content: center;
  display: flex;
  .logo {
    img {
      width: 110px
    }
  }
  @media (min-width: 768px) {
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    .logo {
      img {
        width: 100px;
      }
    }
  }
  .ryanImage {
    img {
      width: 40px;
    }
    display: none;
    @media (min-width: 768px){
      display: block;
    }
  }
}
