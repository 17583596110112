.wrapper {


  @media screen and (min-width: 768px) {
    padding-top: 30px;
  }

  @media (min-width: 992px) {

    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-rows: 145px;

    @media screen and (min-width:1024px) {
      grid-template-rows: 170px;
    }

    @media screen and (min-width:1800px) {
      grid-template-rows: 250px;
    }
  }

  .moveCategoryWrapper {
    grid-column: 2/-1;
  }
  div.moveCategoryWrapper:nth-child(2){
  }
  
  .categoryNavigation {
    grid-column: 2;
    grid-row: 1;
    padding: 0 5px;
    @media screen and (min-width: 786px) {
      padding: 0px 22px;
    }

    @media (min-width: 992px){
      padding: 0px 0px;
    }
  }
  .movieCategory {
    margin-left: 22px;
    padding-top: 20px;
    @media (min-width: 992px){
      margin-left: 0px;
    }
  }
}
.legal {
  grid-row: 5;
  grid-column: 2/-1;
  color: #a3a5a6;
  font-size: 12px;
  line-height: 15px;
  padding: 50px 0px;
  padding-right: 40px;
  margin-left:40px;

  @media screen and (min-width: 990px) {
    margin-left:0;
    padding-right: 0px;
  }
}
