.categoryNavigation {
  width: 100%;
  text-align: center;
  display: flex;
  margin-bottom:20px;
  
  .fifthButton {
    display: none;
    @media screen and (min-width: 768px){
      display: block;
    }
  }

  @media screen and (min-width: 990px) {
    padding: 10px 0;
  }
  a {
    position: relative;
    min-height: 70px;
    margin-right: 15px;
    font-size: 8px;
    text-decoration: none;
    display: inline-block;

    @media screen and (min-width: 360px) {
      font-size: 10px;
      min-height: 100px;
    }

    @media screen and (min-width: 767px) {
      min-height: 120px;
      font-size: 16px;
    }

    .bwl-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  a:last-of-type {
    margin-right: 0;
  }
}